<template>

    
    <div>

        <!-- Section Path File/Modul -->
        <v-breadcrumbs 
            :items="pathModul"
            divider="-"
            normal
            class="pathModul"
        ></v-breadcrumbs>

        <!-- Section Filtering Input/Select/Button -->
        <v-container class="container-filtering">

            <v-row align="center">
 
                <!-- Column Select Station -->
                <!-- <v-col
                class="d-flex"
                cols="12"
                sm="6"
                md="4"
                > -->
                <v-col
                class="col-input-filter"
                >
                    <v-select
                    v-on:change="selectValueStation"
                    :items="itemStation"
                    label="Station"
                    ></v-select>
                </v-col>

                <!-- Column Select Calendar From-->
                <v-col
                class="col-input-filter"
                >
                    <v-menu
                    v-model="modalCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateFrom"
                        label="From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateFrom"
                        @input="modalCalendar = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Column Select Calendar To-->
                <v-col
                class="col-input-filter"
                >
                    <v-menu
                    v-model="modalCalendar2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateTo"
                        label="To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateTo"
                        @input="modalCalendar2 = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Column Generate Button -->
                <v-col
                cols="12"
                sm="6"
                md="4"
                class="col-btn-generateReport"
                >
                    <v-btn
                    class="ma-2 white--text btn-generate primary"
                    solo
                    dense
                    normal
                    @click="generateTable"
                    >
                      Generate Report
                      <v-icon
                      right
                      dark
                      big
                      >
                          mdi-table-arrow-up
                      </v-icon>
                    </v-btn>

                    <v-btn
                    class="btnExportExcelRD"
                    color="green"
                    elevation="2"
                    @click="exportReport('excel')"
                    >
                      <v-icon
                      color="white"
                      center
                      dark
                      >
                          mdi-microsoft-excel
                      </v-icon>
                    </v-btn>
                </v-col>


            </v-row>

                

        </v-container>
        


        <!-- Section Result Generated (TABLE) -->
        <v-container class="container-result-generated">

            <v-subheader
            class="subTitle fontSubTitle-blue"
            >
              Raw Data For ({{valStation}}) From {{this.convertDateFormat(dateFrom)}} To {{this.convertDateFormat(dateTo)}}
            </v-subheader>

            <!-- Form Table -->
            <div>
              <!-- Table -->
              <v-data-table
              :headers="thead_rawData"
              :items="tbody_rawData"
              class="elevation-1 tableRawData headerDtSarawak"
              :items-per-page="10"
              fixed-header
              :loading="loadingTable"
              :loading-text="loadingText"
              >
                <!-- <template v-slot:header>
                  <thead class="header-colspan">
                    <tr>
                      <th>TIME</th>
                      <th>WQI</th>
                      <th colspan="3">DO</th>
                      <th colspan="2">BOD</th>
                      <th colspan="2">COD</th>
                      <th colspan="2">NH3N</th>
                      <th colspan="2">TSS</th>
                      <th colspan="2">pH</th>
                      <th>SpCond</th>
                      <th>Salinity</th>
                      <th>Temp</th>
                      <th>Turb</th>
                      <th>NH4</th>
                      <th>Solar Battery</th>
                    </tr>
                  </thead>
                </template> -->

                <template v-slot:[`item.DATETIME`]="{ item }">
                    <!-- {{ item.DATETIME }} -->
                    <span v-html="item.DATETIME"></span>
                </template>


                <template v-slot:[`item.WQI`]="{ item }">
                    <v-chip
                    :color="getColor(item.WQI)"
                    dark
                    :class="getFontColor(item.WQI)"
                    >
                      {{ item.WQI }}
                    </v-chip>
                </template>

              
                <!-- Star Param -->
                <template v-slot:[`item.DO_SAT`]="{ item }">
                    <span v-if=" item.DO_SAT_MIN == null && item.DO_SAT_MAX == null ">{{ item.DO_SAT }}</span>
                    <span v-else-if=" item.DO_SAT >= item.DO_SAT_MIN && item.DO_SAT <= item.DO_SAT_MAX ">{{ item.DO_SAT }}</span>
                    <span class="red--text" v-else>{{ item.DO_SAT }}</span>
                </template>

                <template v-slot:[`item.DO_CON`]="{ item }">
                    <span v-if=" item.DO_CON_MIN == null && item.DO_CON_MAX == null ">{{ item.DO_CON }}</span>
                    <span v-else-if=" item.DO_CON >= item.DO_CON_MIN && item.DO_CON <= item.DO_CON_MAX ">{{ item.DO_CON }}</span>
                    <span class="red--text" v-else>{{ item.DO_CON }}</span>
                </template>

                <template v-slot:[`item.BOD`]="{ item }">
                    <span v-if=" item.BOD_MIN == null && item.BOD_MAX == null ">{{ item.BOD }}</span>
                    <span v-else-if=" item.BOD >= item.BOD_MIN && item.BOD <= item.BOD_MAX ">{{ item.BOD }}</span>
                    <span class="red--text" v-else>{{ item.BOD }}</span>
                </template>

                <template v-slot:[`item.COD`]="{ item }">
                    <span v-if=" item.COD_MIN == null && item.COD_MAX == null ">{{ item.COD }}</span>
                    <span v-else-if=" item.COD >= item.COD_MIN && item.COD <= item.COD_MAX ">{{ item.COD }}</span>
                    <span class="red--text" v-else>{{ item.COD }}</span>
                </template>
                
                <template v-slot:[`item.NH3N`]="{ item }">
                    <span v-if=" item.NH3N_MIN == null && item.NH3N_MAX == null ">{{ item.NH3N }}</span>
                    <span v-else-if=" item.NH3N >= item.NH3N_MIN && item.NH3N <= item.NH3N_MAX ">{{ item.NH3N }}</span>
                    <span class="red--text" v-else>{{ item.NH3N }}</span>
                </template>

                <template v-slot:[`item.TSS`]="{ item }">
                    <span v-if=" item.TSS_MIN == null && item.TSS_MAX == null ">{{ item.TSS }}</span>
                    <span v-else-if=" item.TSS >= item.TSS_MIN && item.TSS <= item.TSS_MAX ">{{ item.TSS }}</span>
                    <span class="red--text" v-else>{{ item.TSS }}</span>
                </template>

                <template v-slot:[`item.pH`]="{ item }">
                    <span v-if=" item.pH_MIN == null && item.pH_MAX == null ">{{ item.pH }}</span>
                    <span v-else-if=" item.pH >= item.pH_MIN && item.pH <= item.pH_MAX ">{{ item.pH }}</span>
                    <span class="red--text" v-else>{{ item.pH }}</span>
                </template>

                <template v-slot:[`item._4_4_DDT`]="{ item }">
                    <span v-if=" item._4_4_DDT_MIN == null && item._4_4_DDT_MAX == null ">{{ item._4_4_DDT }}</span>
                    <span v-else-if=" item._4_4_DDT >= item._4_4_DDT_MIN && item._4_4_DDT <= item._4_4_DDT_MAX ">{{ item._4_4_DDT }}</span>
                    <span class="red--text" v-else>{{ item._4_4_DDT }}</span>
                </template>

                <template v-slot:[`item.AG`]="{ item }">
                    <span v-if=" item.AG_MIN == null && item.AG_MAX == null ">{{ item.AG }}</span>
                    <span v-else-if=" item.AG >= item.AG_MIN && item.AG <= item.AG_MAX ">{{ item.AG }}</span>
                    <span class="red--text" v-else>{{ item.AG }}</span>
                </template>

                <template v-slot:[`item.AL`]="{ item }">
                    <span v-if=" item.AL_MIN == null && item.AL_MAX == null ">{{ item.AL }}</span>
                    <span v-else-if=" item.AL >= item.AL_MIN && item.AL <= item.AL_MAX ">{{ item.AL }}</span>
                    <span class="red--text" v-else>{{ item.AL }}</span>
                </template>

                <template v-slot:[`item.ALDRIN_DIELDRIN`]="{ item }">
                    <span v-if=" item.ALDRIN_DIELDRIN_MIN == null && item.ALDRIN_DIELDRIN_MAX == null ">{{ item.ALDRIN_DIELDRIN }}</span>
                    <span v-else-if=" item.ALDRIN_DIELDRIN >= item.ALDRIN_DIELDRIN_MIN && item.ALDRIN_DIELDRIN <= item.ALDRIN_DIELDRIN_MAX ">{{ item.ALDRIN_DIELDRIN }}</span>
                    <span class="red--text" v-else>{{ item.ALDRIN_DIELDRIN }}</span>
                </template>

                <template v-slot:[`item.AS`]="{ item }">
                    <span v-if=" item.AS_MIN == null && item.AS_MAX == null ">{{ item.AS }}</span>
                    <span v-else-if=" item.AS >= item.AS_MIN && item.AS <= item.AS_MAX ">{{ item.AS }}</span>
                    <span class="red--text" v-else>{{ item.AS }}</span>
                </template>

                <template v-slot:[`item.B`]="{ item }">
                    <span v-if=" item.B_MIN == null && item.B_MAX == null ">{{ item.B }}</span>
                    <span v-else-if=" item.B >= item.B_MIN && item.B <= item.B_MAX ">{{ item.B }}</span>
                    <span class="red--text" v-else>{{ item.B }}</span>
                </template>

                <template v-slot:[`item.BA`]="{ item }">
                    <span v-if=" item.BA_MIN == null && item.BA_MAX == null ">{{ item.BA }}</span>
                    <span v-else-if=" item.BA >= item.BA_MIN && item.BA <= item.BA_MAX ">{{ item.BA }}</span>
                    <span class="red--text" v-else>{{ item.BA }}</span>
                </template>

                <template v-slot:[`item.BHC`]="{ item }">
                    <span v-if=" item.BHC_MIN == null && item.BHC_MAX == null ">{{ item.BHC }}</span>
                    <span v-else-if=" item.BHC >= item.BHC_MIN && item.BHC <= item.BHC_MAX ">{{ item.BHC }}</span>
                    <span class="red--text" v-else>{{ item.BHC }}</span>
                </template>

                <template v-slot:[`item.BR`]="{ item }">
                    <span v-if=" item.BR_MIN == null && item.BR_MAX == null ">{{ item.BR }}</span>
                    <span v-else-if=" item.BR >= item.BR_MIN && item.BR <= item.BR_MAX ">{{ item.BR }}</span>
                    <span class="red--text" v-else>{{ item.BR }}</span>
                </template>

                <template v-slot:[`item.CA`]="{ item }">
                    <span v-if=" item.CA_MIN == null && item.CA_MAX == null ">{{ item.CA }}</span>
                    <span v-else-if=" item.CA >= item.CA_MIN && item.CA <= item.CA_MAX ">{{ item.CA }}</span>
                    <span class="red--text" v-else>{{ item.CA }}</span>
                </template>

                <template v-slot:[`item.CCE`]="{ item }">
                    <span v-if=" item.CCE_MIN == null && item.CCE_MAX == null ">{{ item.CCE }}</span>
                    <span v-else-if=" item.CCE >= item.CCE_MIN && item.CCE <= item.CCE_MAX ">{{ item.CCE }}</span>
                    <span class="red--text" v-else>{{ item.CCE }}</span>
                </template>

                <template v-slot:[`item.CD`]="{ item }">
                    <span v-if=" item.CD_MIN == null && item.CD_MAX == null ">{{ item.CD }}</span>
                    <span v-else-if=" item.CD >= item.CD_MIN && item.CD <= item.CD_MAX ">{{ item.CD }}</span>
                    <span class="red--text" v-else>{{ item.CD }}</span>
                </template>

                <template v-slot:[`item.CDOM`]="{ item }">
                    <span v-if=" item.CDOM_MIN == null && item.CDOM_MAX == null ">{{ item.CDOM }}</span>
                    <span v-else-if=" item.CDOM >= item.CDOM_MIN && item.CDOM <= item.CDOM_MAX ">{{ item.CDOM }}</span>
                    <span class="red--text" v-else>{{ item.CDOM }}</span>
                </template>

                <template v-slot:[`item.CHLORDANE`]="{ item }">
                    <span v-if=" item.CHLORDANE_MIN == null && item.CHLORDANE_MAX == null ">{{ item.CHLORDANE }}</span>
                    <span v-else-if=" item.CHLORDANE >= item.CHLORDANE_MIN && item.CHLORDANE <= item.CHLORDANE_MAX ">{{ item.CHLORDANE }}</span>
                    <span class="red--text" v-else>{{ item.CHLORDANE }}</span>
                </template>

                <template v-slot:[`item.CHLOROPHYLL_A`]="{ item }">
                    <span v-if=" item.CHLOROPHYLL_A_MIN == null && item.CHLOROPHYLL_A_MAX == null ">{{ item.CHLOROPHYLL_A }}</span>
                    <span v-else-if=" item.CHLOROPHYLL_A >= item.CHLOROPHYLL_A_MIN && item.CHLOROPHYLL_A <= item.CHLOROPHYLL_A_MAX ">{{ item.CHLOROPHYLL_A }}</span>
                    <span class="red--text" v-else>{{ item.CHLOROPHYLL_A }}</span>
                </template>

                <template v-slot:[`item.CL`]="{ item }">
                    <span v-if=" item.CL_MIN == null && item.CL_MAX == null ">{{ item.CL }}</span>
                    <span v-else-if=" item.CL >= item.CL_MIN && item.CL <= item.CL_MAX ">{{ item.CL }}</span>
                    <span class="red--text" v-else>{{ item.CL }}</span>
                </template>

                <template v-slot:[`item.CL2`]="{ item }">
                    <span v-if=" item.CL2_MIN == null && item.CL2_MAX == null ">{{ item.CL2 }}</span>
                    <span v-else-if=" item.CL2 >= item.CL2_MIN && item.CL2 <= item.CL2_MAX ">{{ item.CL2 }}</span>
                    <span class="red--text" v-else>{{ item.CL2 }}</span>
                </template>

                <template v-slot:[`item.CN`]="{ item }">
                    <span v-if=" item.CN_MIN == null && item.CN_MAX == null ">{{ item.CN }}</span>
                    <span v-else-if=" item.CN >= item.CN_MIN && item.CN <= item.CN_MAX ">{{ item.CN }}</span>
                    <span class="red--text" v-else>{{ item.CN }}</span>
                </template>

                <template v-slot:[`item.CO2`]="{ item }">
                    <span v-if=" item.CO2_MIN == null && item.CO2_MAX == null ">{{ item.CO2 }}</span>
                    <span v-else-if=" item.CO2 >= item.CO2_MIN && item.CO2 <= item.CO2_MAX ">{{ item.CO2 }}</span>
                    <span class="red--text" v-else>{{ item.CO2 }}</span>
                </template>
                
                <template v-slot:[`item.COLOUR`]="{ item }">
                    <span v-if=" item.COLOUR_MIN == null && item.COLOUR_MAX == null ">{{ item.COLOUR }}</span>
                    <span v-else-if=" item.COLOUR >= item.COLOUR_MIN && item.COLOUR <= item.COLOUR_MAX ">{{ item.COLOUR }}</span>
                    <span class="red--text" v-else>{{ item.COLOUR }}</span>
                </template>
                
                <template v-slot:[`item.CR`]="{ item }">
                    <span v-if=" item.CR_MIN == null && item.CR_MAX == null ">{{ item.CR }}</span>
                    <span v-else-if=" item.CR >= item.CR_MIN && item.CR <= item.CR_MAX ">{{ item.CR }}</span>
                    <span class="red--text" v-else>{{ item.CR }}</span>
                </template>
                
                <template v-slot:[`item.CR_III`]="{ item }">
                    <span v-if=" item.CR_III_MIN == null && item.CR_III_MAX == null ">{{ item.CR_III }}</span>
                    <span v-else-if=" item.CR_III >= item.CR_III_MIN && item.CR_III <= item.CR_III_MAX ">{{ item.CR_III }}</span>
                    <span class="red--text" v-else>{{ item.CR_III }}</span>
                </template>
                
                <template v-slot:[`item.CR_IV`]="{ item }">
                    <span v-if=" item.CR_IV_MIN == null && item.CR_IV_MAX == null ">{{ item.CR_IV }}</span>
                    <span v-else-if=" item.CR_IV >= item.CR_IV_MIN && item.CR_IV <= item.CR_IV_MAX ">{{ item.CR_IV }}</span>
                    <span class="red--text" v-else>{{ item.CR_IV }}</span>
                </template>
                
                <template v-slot:[`item.CU`]="{ item }">
                    <span v-if=" item.CU_MIN == null && item.CU_MAX == null ">{{ item.CU }}</span>
                    <span v-else-if=" item.CU >= item.CU_MIN && item.CU <= item.CU_MAX ">{{ item.CU }}</span>
                    <span class="red--text" v-else>{{ item.CU }}</span>
                </template>
                
                <template v-slot:[`item.DEPTH`]="{ item }">
                    <span v-if=" item.DEPTH_MIN == null && item.DEPTH_MAX == null ">{{ item.DEPTH }}</span>
                    <span v-else-if=" item.DEPTH >= item.DEPTH_MIN && item.DEPTH <= item.DEPTH_MAX ">{{ item.DEPTH }}</span>
                    <span class="red--text" v-else>{{ item.DEPTH }}</span>
                </template>
                
                <template v-slot:[`item.DOC`]="{ item }">
                    <span v-if=" item.DOC_MIN == null && item.DOC_MAX == null ">{{ item.DOC }}</span>
                    <span v-else-if=" item.DOC >= item.DOC_MIN && item.DOC <= item.DOC_MAX ">{{ item.DOC }}</span>
                    <span class="red--text" v-else>{{ item.DOC }}</span>
                </template>
                
                <template v-slot:[`item.E_COLI`]="{ item }">
                    <span v-if=" item.E_COLI_MIN == null && item.E_COLI_MAX == null ">{{ item.E_COLI }}</span>
                    <span v-else-if=" item.E_COLI >= item.E_COLI_MIN && item.E_COLI <= item.E_COLI_MAX ">{{ item.E_COLI }}</span>
                    <span class="red--text" v-else>{{ item.E_COLI }}</span>
                </template>
                
                <template v-slot:[`item.EC`]="{ item }">
                    <span v-if=" item.EC_MIN == null && item.EC_MAX == null ">{{ item.EC }}</span>
                    <span v-else-if=" item.EC >= item.EC_MIN && item.EC <= item.EC_MAX ">{{ item.EC }}</span>
                    <span class="red--text" v-else>{{ item.EC }}</span>
                </template>
                
                <template v-slot:[`item.ENDOSULFAN`]="{ item }">
                    <span v-if=" item.ENDOSULFAN_MIN == null && item.ENDOSULFAN_MAX == null ">{{ item.ENDOSULFAN }}</span>
                    <span v-else-if=" item.ENDOSULFAN >= item.ENDOSULFAN_MIN && item.ENDOSULFAN <= item.ENDOSULFAN_MAX ">{{ item.ENDOSULFAN }}</span>
                    <span class="red--text" v-else>{{ item.ENDOSULFAN }}</span>
                </template>
                
                <template v-slot:[`item.F`]="{ item }">
                    <span v-if=" item.F_MIN == null && item.F_MAX == null ">{{ item.F }}</span>
                    <span v-else-if=" item.F >= item.F_MIN && item.F <= item.F_MAX ">{{ item.F }}</span>
                    <span class="red--text" v-else>{{ item.F }}</span>
                </template>
                
                <template v-slot:[`item.FC`]="{ item }">
                    <span v-if=" item.FC_MIN == null && item.FC_MAX == null ">{{ item.FC }}</span>
                    <span v-else-if=" item.FC >= item.FC_MIN && item.FC <= item.FC_MAX ">{{ item.FC }}</span>
                    <span class="red--text" v-else>{{ item.FC }}</span>
                </template>
                
                <template v-slot:[`item.FDOM`]="{ item }">
                    <span v-if=" item.FDOM_MIN == null && item.FDOM_MAX == null ">{{ item.FDOM }}</span>
                    <span v-else-if=" item.FDOM >= item.FDOM_MIN && item.FDOM <= item.FDOM_MAX ">{{ item.FDOM }}</span>
                    <span class="red--text" v-else>{{ item.FDOM }}</span>
                </template>
                
                <template v-slot:[`item.FE`]="{ item }">
                    <span v-if=" item.FE_MIN == null && item.FE_MAX == null ">{{ item.FE }}</span>
                    <span v-else-if=" item.FE >= item.FE_MIN && item.FE <= item.FE_MAX ">{{ item.FE }}</span>
                    <span class="red--text" v-else>{{ item.FE }}</span>
                </template>
                
                <template v-slot:[`item.FLOWRATE`]="{ item }">
                    <span v-if=" item.FLOWRATE_MIN == null && item.FLOWRATE_MAX == null ">{{ item.FLOWRATE }}</span>
                    <span v-else-if=" item.FLOWRATE >= item.FLOWRATE_MIN && item.FLOWRATE <= item.FLOWRATE_MAX ">{{ item.FLOWRATE }}</span>
                    <span class="red--text" v-else>{{ item.FLOWRATE }}</span>
                </template>
                
                <template v-slot:[`item.GROSS_ALPHA`]="{ item }">
                    <span v-if=" item.GROSS_ALPHA_MIN == null && item.GROSS_ALPHA_MAX == null ">{{ item.GROSS_ALPHA }}</span>
                    <span v-else-if=" item.GROSS_ALPHA >= item.GROSS_ALPHA_MIN && item.GROSS_ALPHA <= item.GROSS_ALPHA_MAX ">{{ item.GROSS_ALPHA }}</span>
                    <span class="red--text" v-else>{{ item.GROSS_ALPHA }}</span>
                </template>
                
                <template v-slot:[`item.GROSS_BETA`]="{ item }">
                    <span v-if=" item.GROSS_BETA_MIN == null && item.GROSS_BETA_MAX == null ">{{ item.GROSS_BETA }}</span>
                    <span v-else-if=" item.GROSS_BETA >= item.GROSS_BETA_MIN && item.GROSS_BETA <= item.GROSS_BETA_MAX ">{{ item.GROSS_BETA }}</span>
                    <span class="red--text" v-else>{{ item.GROSS_BETA }}</span>
                </template>
                
                <template v-slot:[`item.HARDNESS`]="{ item }">
                    <span v-if=" item.HARDNESS_MIN == null && item.HARDNESS_MAX == null ">{{ item.HARDNESS }}</span>
                    <span v-else-if=" item.HARDNESS >= item.HARDNESS_MIN && item.HARDNESS <= item.HARDNESS_MAX ">{{ item.HARDNESS }}</span>
                    <span class="red--text" v-else>{{ item.HARDNESS }}</span>
                </template>
                
                <template v-slot:[`item.HEDONAL_2_4_D`]="{ item }">
                    <span v-if=" item.HEDONAL_2_4_D_MIN == null && item.HEDONAL_2_4_D_MAX == null ">{{ item.HEDONAL_2_4_D }}</span>
                    <span v-else-if=" item.HEDONAL_2_4_D >= item.HEDONAL_2_4_D_MIN && item.HEDONAL_2_4_D <= item.HEDONAL_2_4_D_MAX ">{{ item.HEDONAL_2_4_D }}</span>
                    <span class="red--text" v-else>{{ item.HEDONAL_2_4_D }}</span>
                </template>
                
                <template v-slot:[`item.HEPTACHLOR_EPOXIDE`]="{ item }">
                    <span v-if=" item.HEPTACHLOR_EPOXIDE_MIN == null && item.HEPTACHLOR_EPOXIDE_MAX == null ">{{ item.HEPTACHLOR_EPOXIDE }}</span>
                    <span v-else-if=" item.HEPTACHLOR_EPOXIDE >= item.HEPTACHLOR_EPOXIDE_MIN && item.HEPTACHLOR_EPOXIDE <= item.HEPTACHLOR_EPOXIDE_MAX ">{{ item.HEPTACHLOR_EPOXIDE }}</span>
                    <span class="red--text" v-else>{{ item.HEPTACHLOR_EPOXIDE }}</span>
                </template>
                
                <template v-slot:[`item.HG`]="{ item }">
                    <span v-if=" item.HG_MIN == null && item.HG_MAX == null ">{{ item.HG }}</span>
                    <span v-else-if=" item.HG >= item.HG_MIN && item.HG <= item.HG_MAX ">{{ item.HG }}</span>
                    <span class="red--text" v-else>{{ item.HG }}</span>
                </template>
                
                <template v-slot:[`item.K`]="{ item }">
                    <span v-if=" item.K_MIN == null && item.K_MAX == null ">{{ item.K }}</span>
                    <span v-else-if=" item.K >= item.K_MIN && item.K <= item.K_MAX ">{{ item.K }}</span>
                    <span class="red--text" v-else>{{ item.K }}</span>
                </template>
                
                <template v-slot:[`item.LINDANE`]="{ item }">
                    <span v-if=" item.LINDANE_MIN == null && item.LINDANE_MAX == null ">{{ item.LINDANE }}</span>
                    <span v-else-if=" item.LINDANE >= item.LINDANE_MIN && item.LINDANE <= item.LINDANE_MAX ">{{ item.LINDANE }}</span>
                    <span class="red--text" v-else>{{ item.LINDANE }}</span>
                </template>
                
                <template v-slot:[`item.MBAS`]="{ item }">
                    <span v-if=" item.MBAS_MIN == null && item.MBAS_MAX == null ">{{ item.MBAS }}</span>
                    <span v-else-if=" item.MBAS >= item.MBAS_MIN && item.MBAS <= item.MBAS_MAX ">{{ item.MBAS }}</span>
                    <span class="red--text" v-else>{{ item.MBAS }}</span>
                </template>
                
                <template v-slot:[`item.MG`]="{ item }">
                    <span v-if=" item.MG_MIN == null && item.MG_MAX == null ">{{ item.MG }}</span>
                    <span v-else-if=" item.MG >= item.MG_MIN && item.MG <= item.MG_MAX ">{{ item.MG }}</span>
                    <span class="red--text" v-else>{{ item.MG }}</span>
                </template>
                
                <template v-slot:[`item.MN`]="{ item }">
                    <span v-if=" item.MN_MIN == null && item.MN_MAX == null ">{{ item.MN }}</span>
                    <span v-else-if=" item.MN >= item.MN_MIN && item.MN <= item.MN_MAX ">{{ item.MN }}</span>
                    <span class="red--text" v-else>{{ item.MN }}</span>
                </template>
                
                <template v-slot:[`item.NA`]="{ item }">
                    <span v-if=" item.NA_MIN == null && item.NA_MAX == null ">{{ item.NA }}</span>
                    <span v-else-if=" item.NA >= item.NA_MIN && item.NA <= item.NA_MAX ">{{ item.NA }}</span>
                    <span class="red--text" v-else>{{ item.NA }}</span>
                </template>
                
                <template v-slot:[`item.NH4`]="{ item }">
                    <span v-if=" item.NH4_MIN == null && item.NH4_MAX == null ">{{ item.NH4 }}</span>
                    <span v-else-if=" item.NH4 >= item.NH4_MIN && item.NH4 <= item.NH4_MAX ">{{ item.NH4 }}</span>
                    <span class="red--text" v-else>{{ item.NH4 }}</span>
                </template>
                
                <template v-slot:[`item.NI`]="{ item }">
                    <span v-if=" item.NI_MIN == null && item.NI_MAX == null ">{{ item.NI }}</span>
                    <span v-else-if=" item.NI >= item.NI_MIN && item.NI <= item.NI_MAX ">{{ item.NI }}</span>
                    <span class="red--text" v-else>{{ item.NI }}</span>
                </template>
                
                <template v-slot:[`item.NO2`]="{ item }">
                    <span v-if=" item.NO2_MIN == null && item.NO2_MAX == null ">{{ item.NO2 }}</span>
                    <span v-else-if=" item.NO2 >= item.NO2_MIN && item.NO2 <= item.NO2_MAX ">{{ item.NO2 }}</span>
                    <span class="red--text" v-else>{{ item.NO2 }}</span>
                </template>
                
                <template v-slot:[`item.NO3`]="{ item }">
                    <span v-if=" item.NO3_MIN == null && item.NO3_MAX == null ">{{ item.NO3 }}</span>
                    <span v-else-if=" item.NO3 >= item.NO3_MIN && item.NO3 <= item.NO3_MAX ">{{ item.NO3 }}</span>
                    <span class="red--text" v-else>{{ item.NO3 }}</span>
                </template>
                
                <template v-slot:[`item.OG_EDIBLE`]="{ item }">
                    <span v-if=" item.OG_EDIBLE_MIN == null && item.OG_EDIBLE_MAX == null ">{{ item.OG_EDIBLE }}</span>
                    <span v-else-if=" item.OG_EDIBLE >= item.OG_EDIBLE_MIN && item.OG_EDIBLE <= item.OG_EDIBLE_MAX ">{{ item.OG_EDIBLE }}</span>
                    <span class="red--text" v-else>{{ item.OG_EDIBLE }}</span>
                </template>
                
                <template v-slot:[`item.OG_MINERAL`]="{ item }">
                    <span v-if=" item.OG_MINERAL_MIN == null && item.OG_MINERAL_MAX == null ">{{ item.OG_MINERAL }}</span>
                    <span v-else-if=" item.OG_MINERAL >= item.OG_MINERAL_MIN && item.OG_MINERAL <= item.OG_MINERAL_MAX ">{{ item.OG_MINERAL }}</span>
                    <span class="red--text" v-else>{{ item.OG_MINERAL }}</span>
                </template>
                
                <template v-slot:[`item.P`]="{ item }">
                    <span v-if=" item.P_MIN == null && item.P_MAX == null ">{{ item.P }}</span>
                    <span v-else-if=" item.P >= item.P_MIN && item.P <= item.P_MAX ">{{ item.P }}</span>
                    <span class="red--text" v-else>{{ item.P }}</span>
                </template>
                
                <template v-slot:[`item.PARAQUAT`]="{ item }">
                    <span v-if=" item.PARAQUAT_MIN == null && item.PARAQUAT_MAX == null ">{{ item.PARAQUAT }}</span>
                    <span v-else-if=" item.PARAQUAT >= item.PARAQUAT_MIN && item.PARAQUAT <= item.PARAQUAT_MAX ">{{ item.PARAQUAT }}</span>
                    <span class="red--text" v-else>{{ item.PARAQUAT }}</span>
                </template>
                
                <template v-slot:[`item.PB`]="{ item }">
                    <span v-if=" item.PB_MIN == null && item.PB_MAX == null ">{{ item.PB }}</span>
                    <span v-else-if=" item.PB >= item.PB_MIN && item.PB <= item.PB_MAX ">{{ item.PB }}</span>
                    <span class="red--text" v-else>{{ item.PB }}</span>
                </template>
                
                <template v-slot:[`item.PCB`]="{ item }">
                    <span v-if=" item.PCB_MIN == null && item.PCB_MAX == null ">{{ item.PCB }}</span>
                    <span v-else-if=" item.PCB >= item.PCB_MIN && item.PCB <= item.PCB_MAX ">{{ item.PCB }}</span>
                    <span class="red--text" v-else>{{ item.PCB }}</span>
                </template>
                
                <template v-slot:[`item.PHENOL`]="{ item }">
                    <span v-if=" item.PHENOL_MIN == null && item.PHENOL_MAX == null ">{{ item.PHENOL }}</span>
                    <span v-else-if=" item.PHENOL >= item.PHENOL_MIN && item.PHENOL <= item.PHENOL_MAX ">{{ item.PHENOL }}</span>
                    <span class="red--text" v-else>{{ item.PHENOL }}</span>
                </template>
                
                <template v-slot:[`item.PO4_3`]="{ item }">
                    <span v-if=" item.PO4_3_MIN == null && item.PO4_3_MAX == null ">{{ item.PO4_3 }}</span>
                    <span v-else-if=" item.PO4_3 >= item.PO4_3_MIN && item.PO4_3 <= item.PO4_3_MAX ">{{ item.PO4_3 }}</span>
                    <span class="red--text" v-else>{{ item.PO4_3 }}</span>
                </template>
                
                <template v-slot:[`item.RA226`]="{ item }">
                    <span v-if=" item.RA226_MIN == null && item.RA226_MAX == null ">{{ item.RA226 }}</span>
                    <span v-else-if=" item.RA226 >= item.RA226_MIN && item.RA226 <= item.RA226_MAX ">{{ item.RA226 }}</span>
                    <span class="red--text" v-else>{{ item.RA226 }}</span>
                </template>
                
                <template v-slot:[`item.S`]="{ item }">
                    <span v-if=" item.S_MIN == null && item.S_MAX == null ">{{ item.S }}</span>
                    <span v-else-if=" item.S >= item.S_MIN && item.S <= item.S_MAX ">{{ item.S }}</span>
                    <span class="red--text" v-else>{{ item.S }}</span>
                </template>
                
                <template v-slot:[`item.SALINITY`]="{ item }">
                    <span v-if=" item.SALINITY_MIN == null && item.SALINITY_MAX == null ">{{ item.SALINITY }}</span>
                    <span v-else-if=" item.SALINITY >= item.SALINITY_MIN && item.SALINITY <= item.SALINITY_MAX ">{{ item.SALINITY }}</span>
                    <span class="red--text" v-else>{{ item.SALINITY }}</span>
                </template>
                
                <template v-slot:[`item.SE`]="{ item }">
                    <span v-if=" item.SE_MIN == null && item.SE_MAX == null ">{{ item.SE }}</span>
                    <span v-else-if=" item.SE >= item.SE_MIN && item.SE <= item.SE_MAX ">{{ item.SE }}</span>
                    <span class="red--text" v-else>{{ item.SE }}</span>
                </template>
                
                <template v-slot:[`item.SILICA`]="{ item }">
                    <span v-if=" item.SILICA_MIN == null && item.SILICA_MAX == null ">{{ item.SILICA }}</span>
                    <span v-else-if=" item.SILICA >= item.SILICA_MIN && item.SILICA <= item.SILICA_MAX ">{{ item.SILICA }}</span>
                    <span class="red--text" v-else>{{ item.SILICA }}</span>
                </template>
                
                <template v-slot:[`item.SILVEX_2_4_5_TP`]="{ item }">
                    <span v-if=" item.SILVEX_2_4_5_TP_MIN == null && item.SILVEX_2_4_5_TP_MAX == null ">{{ item.SILVEX_2_4_5_TP }}</span>
                    <span v-else-if=" item.SILVEX_2_4_5_TP >= item.SILVEX_2_4_5_TP_MIN && item.SILVEX_2_4_5_TP <= item.SILVEX_2_4_5_TP_MAX ">{{ item.SILVEX_2_4_5_TP }}</span>
                    <span class="red--text" v-else>{{ item.SILVEX_2_4_5_TP }}</span>
                </template>
                
                <template v-slot:[`item.SN`]="{ item }">
                    <span v-if=" item.SN_MIN == null && item.SN_MAX == null ">{{ item.SN }}</span>
                    <span v-else-if=" item.SN >= item.SN_MIN && item.SN <= item.SN_MAX ">{{ item.SN }}</span>
                    <span class="red--text" v-else>{{ item.SN }}</span>
                </template>
                
                <template v-slot:[`item.SO4`]="{ item }">
                    <span v-if=" item.SO4_MIN == null && item.SO4_MAX == null ">{{ item.SO4 }}</span>
                    <span v-else-if=" item.SO4 >= item.SO4_MIN && item.SO4 <= item.SO4_MAX ">{{ item.SO4 }}</span>
                    <span class="red--text" v-else>{{ item.SO4 }}</span>
                </template>
                
                <template v-slot:[`item.SOLAR_BATTERY`]="{ item }">
                    <span v-if=" item.SOLAR_BATTERY_MIN == null && item.SOLAR_BATTERY_MAX == null ">{{ item.SOLAR_BATTERY }}</span>
                    <span v-else-if=" item.SOLAR_BATTERY >= item.SOLAR_BATTERY_MIN && item.SOLAR_BATTERY <= item.SOLAR_BATTERY_MAX ">{{ item.SOLAR_BATTERY }}</span>
                    <span class="red--text" v-else>{{ item.SOLAR_BATTERY }}</span>
                </template>
                
                <template v-slot:[`item.SR_90`]="{ item }">
                    <span v-if=" item.SR_90_MIN == null && item.SR_90_MAX == null ">{{ item.SR_90 }}</span>
                    <span v-else-if=" item.SR_90 >= item.SR_90_MIN && item.SR_90 <= item.SR_90_MAX ">{{ item.SR_90 }}</span>
                    <span class="red--text" v-else>{{ item.SR_90 }}</span>
                </template>
                
                <template v-slot:[`item.TC`]="{ item }">
                    <span v-if=" item.TC_MIN == null && item.TC_MAX == null ">{{ item.TC }}</span>
                    <span v-else-if=" item.TC >= item.TC_MIN && item.TC <= item.TC_MAX ">{{ item.TC }}</span>
                    <span class="red--text" v-else>{{ item.TC }}</span>
                </template>
                
                <template v-slot:[`item.TDS`]="{ item }">
                    <span v-if=" item.TDS_MIN == null && item.TDS_MAX == null ">{{ item.TDS }}</span>
                    <span v-else-if=" item.TDS >= item.TDS_MIN && item.TDS <= item.TDS_MAX ">{{ item.TDS }}</span>
                    <span class="red--text" v-else>{{ item.TDS }}</span>
                </template>
                
                <template v-slot:[`item.TEMPERATURE`]="{ item }">
                    <span v-if=" item.TEMPERATURE_MIN == null && item.TEMPERATURE_MAX == null ">{{ item.TEMPERATURE }}</span>
                    <span v-else-if=" item.TEMPERATURE >= item.TEMPERATURE_MIN && item.TEMPERATURE <= item.TEMPERATURE_MAX ">{{ item.TEMPERATURE }}</span>
                    <span class="red--text" v-else>{{ item.TEMPERATURE }}</span>
                </template>
                
                <template v-slot:[`item.TOC`]="{ item }">
                    <span v-if=" item.TOC_MIN == null && item.TOC_MAX == null ">{{ item.TOC }}</span>
                    <span v-else-if=" item.TOC >= item.TOC_MIN && item.TOC <= item.TOC_MAX ">{{ item.TOC }}</span>
                    <span class="red--text" v-else>{{ item.TOC }}</span>
                </template>
                
                <template v-slot:[`item.TRIOXONE_2_4_5_T`]="{ item }">
                    <span v-if=" item.TRIOXONE_2_4_5_T_MIN == null && item.TRIOXONE_2_4_5_T_MAX == null ">{{ item.TRIOXONE_2_4_5_T }}</span>
                    <span v-else-if=" item.TRIOXONE_2_4_5_T >= item.TRIOXONE_2_4_5_T_MIN && item.TRIOXONE_2_4_5_T <= item.TRIOXONE_2_4_5_T_MAX ">{{ item.TRIOXONE_2_4_5_T }}</span>
                    <span class="red--text" v-else>{{ item.TRIOXONE_2_4_5_T }}</span>
                </template>
                
                <template v-slot:[`item.TURBIDITY`]="{ item }">
                    <span v-if=" item.TURBIDITY_MIN == null && item.TURBIDITY_MAX == null ">{{ item.TURBIDITY }}</span>
                    <span v-else-if=" item.TURBIDITY >= item.TURBIDITY_MIN && item.TURBIDITY <= item.TURBIDITY_MAX ">{{ item.TURBIDITY }}</span>
                    <span class="red--text" v-else>{{ item.TURBIDITY }}</span>
                </template>
                
                <template v-slot:[`item.U`]="{ item }">
                    <span v-if=" item.U_MIN == null && item.U_MAX == null ">{{ item.U }}</span>
                    <span v-else-if=" item.U >= item.U_MIN && item.U <= item.U_MAX ">{{ item.U }}</span>
                    <span class="red--text" v-else>{{ item.U }}</span>
                </template>
                
                <template v-slot:[`item.VELOCITY`]="{ item }">
                    <span v-if=" item.VELOCITY_MIN == null && item.VELOCITY_MAX == null ">{{ item.VELOCITY }}</span>
                    <span v-else-if=" item.VELOCITY >= item.VELOCITY_MIN && item.VELOCITY <= item.VELOCITY_MAX ">{{ item.VELOCITY }}</span>
                    <span class="red--text" v-else>{{ item.VELOCITY }}</span>
                </template>
                
                <template v-slot:[`item.ZN`]="{ item }">
                    <span v-if=" item.ZN_MIN == null && item.ZN_MAX == null ">{{ item.ZN }}</span>
                    <span v-else-if=" item.ZN >= item.ZN_MIN && item.ZN <= item.ZN_MAX ">{{ item.ZN }}</span>
                    <span class="red--text" v-else>{{ item.ZN }}</span>
                </template>


                <!-- TAMBAHAN PARAM! -->
                <template v-slot:[`item.TRYPHPTOPHAN`]="{ item }">
                    <span v-if=" item.TRYPHPTOPHAN_MIN == null && item.TRYPHPTOPHAN_MAX == null ">{{ item.TRYPHPTOPHAN }}</span>
                    <span v-else-if=" item.TRYPHPTOPHAN >= item.TRYPHPTOPHAN_MIN && item.ZN <= item.TRYPHPTOPHAN_MAX ">{{ item.TRYPHPTOPHAN }}</span>
                    <span class="red--text" v-else>{{ item.TRYPHPTOPHAN }}</span>
                </template>

                <!-- End Param Slot -->


              </v-data-table>

              <p style="font-size:0.90em;text-align:left;">
                <b>
                  Note: Red text indicated that value has exceeded above maximum & minimum value
                </b>
              </p>
            

            </div>

            

        </v-container>


        <!-- <template>
          <v-footer padless>
            <v-col
              class="text-center footer-report"
              cols="12"
            >
              <span class="one-text-footer">
                <span class="red--text">C</span>
                <span class="blue--text">=CALIBRATION</span>
              </span>
              <span class="one-text-footer">
                <span class="red--text">M</span>
                <span class="blue--text">=MAINTENANCE</span>
              </span>
              <span class="one-text-footer">
                <span class="red--text">DOR</span>
                <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
              </span>
              <span class="one-text-footer">
                <span class="red--text">OUTL</span>
                <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer">
                <span class="red--text">NA</span>
                <span class="blue--text">=DATA NOT AVAILABLE</span>
              </span>

            </v-col>
          </v-footer>
        </template> -->

    </div>

    

</template>


<script>

import axios from 'axios';
import Papa from "papaparse";

export default {

    
    data: () => ({

        GlobalDataBody: [],

        pathModul: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
                // href: '/Mapviewer',
            },
            {
                text: 'Report - Raw Data',
                disabled: true,
                href: '/Report/RawData',
            },
        ],
        itemStation: [
          // 'CMS_1 - Bakun HEP',
          // 'CMS_2 - Rh.Apan,Long Menjawah',
          // 'CMS_3 - Kapit Water Intake',
          // 'CMS_4 - Rh. Bawai, Baleh'
        ],
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modalCalendar: false,
        menu2: false,
        modalCalendar2: false,

        valStation: null,
        listParam: [],

        // Table
        loadingTable: false,
        loadingText: "",
        thead_rawData: [],
        headerDate : {
          text: 'Datetime',
          sortable: true,
          value: 'DATETIME',
          divider: true,
          align: 'center',
        },
        headerStation : {
          text: 'Station ID',
          sortable: true,
          value: 'STATION_ID',
          divider: true,
          align: 'center',
        },
        firstLoadtbody_rawData: [],
        tbody_rawData: [],


        allParams: [
          "TEMPERATURE",
          "WQI",
          "SI_DO_SAT",
          "DO_CON",
          "DO_SAT",
          "SI_BOD",
          "BOD",
          "SI_COD",
          "COD",
          "SI_NH3N",
          "NH3N",
          "SI_TSS",
          "TSS",
          "SI_pH",
          "pH",
          "_4_4_DDT",
          "AG",
          "AL",
          "ALDRIN_DIELDRIN",
          "AS",
          "B",
          "BA",
          "BHC",
          "BR",
          "CA",
          "CCE",
          "CD",
          "CDOM",
          "CHLORDANE",
          "CHLOROPHYLL_A",
          "CL",
          "CL2",
          "CN",
          "CO2",
          "COLOUR",
          "CR",
          "CR_III",
          "CR_IV",
          "CU",
          "DEPTH",
          "DOC",
          "E_COLI",
          "EC",
          "ENDOSULFAN",
          "F",
          "FC",
          "FDOM",
          "FE",
          "FLOWRATE",
          "GROSS_ALPHA",
          "GROSS_BETA",
          "HARDNESS",
          "HEDONAL_2_4_D",
          "HEPTACHLOR_EPOXIDE",
          "HG",
          "K",
          "LINDANE",
          "MBAS",
          "MG",
          "MN",
          "NA",
          "NH4",
          "NI",
          "NO2",
          "NO3",
          "OG_EDIBLE",
          "OG_MINERAL",
          "P",
          "PARAQUAT",
          "PB",
          "PCB",
          "PHENOL",
          "PO4_3",
          "RA226",
          "S",
          "SALINITY",
          "SE",
          "SILICA",
          "SILVEX_2_4_5_TP",
          "SN",
          "SO4",
          "SOLAR_BATTERY",
          "SR_90",
          "TC",
          "TDS",
          "TOC",
          "TRIOXONE_2_4_5_T",
          "TURBIDITY",
          "U",
          "VELOCITY",
          "ZN",
        ]

        
    }),
    methods: {

      convert12Hours(time) {
          var H = +time.substr(0, 2);
          var h = H % 12 || 12;
          var ampm = (H < 12 || H === 24) ? "AM" : "PM";
          time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
          return time;
      },

      convertDateFormat(date){
          var dd = date.split("-")[2];
          var mm = date.split("-")[1];
          var yyyy = date.split("-")[0];
          date = dd + "/" + mm + "/" + yyyy;
          return date;
      },

      // Anything need to load first refresh
      load(){
        // console.log(this);
        this.getListStation();
        // this.testtt();
      },

      getListStation(){
        axios.get('https://temptunnel1.shaz.my/api/cms/stations', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            // console.log(response.data);
            this.itemStation = [];

            for (let i = 0; i < response.data.length; i++) {
              this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
            }
            
        })
        .catch(error => {
            console.log(error);
        })
      },

      // testtt(){
      //   axios.get('https://temptunnel1.shaz.my/api/cms/params', {
      //         headers: {
      //             'Authorization': 'Bearer ' + this.$store.getters.user.token,
      //         }
      //     })
      //     .then((response) => {
      //         console.log(response.data.length);
              
      //     })
      //     .catch(error => {
      //         console.log(error);
      //     })
      // },

      loadDataQAQC(){

        this.tbody_rawData = [];

        axios.get('https://temptunnel1.shaz.my/api/cms/qaqc1', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            // console.log(response.data);
            let data =  response.data;
            let keyData = Object.keys(data[0])

            // console.log(data);
            // console.log(this.firstLoadtbody_rawData);

            for (let i = 0; i < this.firstLoadtbody_rawData.length; i++) {
              for (let k = 0; k < keyData.length; k++) {
                // if (keyData[k].includes("STATION_ID")) {
                //   console.log("abaikan");
                // }
                if (keyData[k].includes("STATION_ID") == false){

                  // this.tbody_rawData[i][keyData[k]] = null;

                  for (let x = 0; x < data.length; x++) {
                    if (this.firstLoadtbody_rawData[i].station_a == data[x].STATION_ID) {
                      // this.tbody_rawData[i][keyData[k]] == data[x][keyData[k]];
                      this.firstLoadtbody_rawData[i][keyData[k]] = data[x][keyData[k]];
                      // console.log(data[x]);
                    }
                  }

                }
                
              }
            }

            this.tbody_rawData = this.firstLoadtbody_rawData;
            this.loadingTable = false;
            this.loadingText = "";

            // console.log(this.tbody_rawData);
            

            document.querySelector(".subTitle").style.display = "unset";


            // console.log(this.tbody_rawData);
            
        })
        .catch(error => {
            console.log(error);
        })
      },

      handler() {
        var args = arguments;
        for (var arg of args) {
          if (arg instanceof Function) {
            arg();
          }
        }
      },

      // Get value from Selected Station
      selectValueStation: function(e) {
        this.valStation = e.split(' - ')[0];
        // console.log(this.valStation);
      },

      getParamHeader(station){

        // console.log(station);

        axios.get('https://temptunnel1.shaz.my/api/cms/params?stationid='+station+'&subindex=0',{
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {
              // this.stationDetails = response.data;
              // console.log(response.data);
              let key = [
                'text',
                'value',
                'divider',
                'align'
              ],
              result = response.data.map(o => Object.assign(...key.map(k => ({ [k]: o[k] }))));

              // console.log(result);

              this.listParam.push(result);
              // this.thead_rawData = this.listParam[0];
              this.thead_rawData = result;
              this.thead_rawData.unshift(this.headerStation);
              this.thead_rawData.unshift(this.headerDate);

              // console.log(this.listParam);
              this.loadDataBody();

          })
          .catch(error => {
              console.log(error);
          })
      },

      // Get Data from API Params
      loadDataBody(){

        this.firstLoadtbody_rawData = [];
        this.tbody_rawData = [];

        axios.get('https://temptunnel1.shaz.my/api/cms/rawdata?startdt='+ this.dateFrom +'&enddt=' + this.dateTo + '&stationid=all&raw=1',{
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {

              // console.log(response.data);

              // this.stationDetails = response.data;
              // console.log(response.data);
              this.GlobalDataBody = response.data;
              let keyData = Object.keys(response.data[0])
              for (let i = 0; i < response.data.length; i++) {
                

                // for (let x = 0; x < keyData.length; x++) {
                //   if (response.data[i][keyData[x]] <= -1 || response.data[i][keyData[x]] == null) {
                //     response.data[i][keyData[x]] = "N/A";
                //   } 
                // }
                
                // response.data[i]["DATETIME"] = response.data[i]["DATETIME"].replaceAll(' ', '<br>');
                response.data[i]["DATETIME"] = this.convertDateFormat(response.data[i]["DATETIME"].split(" ")[0]) 
                + "<br>"
                + this.convert12Hours(response.data[i]["DATETIME"].split(" ")[1]);
                
                // for (let z = 0; z < keyData.length; z++) {
                //   if (keyData[z] != "station_a" || keyData[z] != "location" || keyData[z] != "epochdate"
                //   || keyData[z] != "DATETIME" || keyData[z] != "STATION_ID" || keyData[z] != "DATETIME"
                //   || keyData[z] != "DATETIME"
                //   ) {
                //     response.data[i][keyData[z]] = response.data[i][keyData[z]] + "<br>" + response.data[i][keyData[z]]+"_FLAG";
                //   }
                // }

              }

              for (let x = 0; x < response.data.length; x++) {
                if (response.data[x]["station_a"] === this.valStation) {
                  // console.log(response.data[x]);
                  this.firstLoadtbody_rawData.push(response.data[x])
                  
                  // this.GlobalDataBody.push(response.data[x]);
                }
              }

              if (this.firstLoadtbody_rawData.length != 0) {
                let myInterval = setInterval(() => {
                    this.loadDataQAQC();
                    clearInterval(myInterval);
                }, 1000);
              }
              else{
                this.loadingTable = false;
                this.loadingText = "";
              }
              
              // console.log(this.tbody_rawData);
              // this.loadDataBody();
              

              

              // buttonTriggerBug.click();


              
              
              // console.log(this.GlobalDataBody);

              // this.tbody_rawData = this.GlobalDataBody[0];

              // this.tbody_rawData.forEach(function(res){
              //   res[keyData]
              // });


          })
          .catch(error => {
              console.log(error);
          })
      },

      // Section Table Function
      generateTable(){

        this.loadingTable = true;
        this.loadingText = "Data Loading... Please wait";

        this.thead_rawData = [];
        this.tbody_rawData = [];
        this.getParamHeader(this.valStation);
        
      },

      // Customize background color for column table for result WQI status
      getColor(wqi) {
        if (wqi > 92.7){
          return '#3486FA'
        }
        else if (wqi > 76.5 && wqi <= 92.7) {
          return '#7AE666'
        }
        else if (wqi >= 50 && wqi <= 76.5) {
          return '#FFFF10'
        }
        else if (wqi >= 31.0 && wqi <= 51.9) {
          return '#FFAB3D'
        }
        else if(wqi >= 1 && wqi <= 30.9){
          return '#FB401D'
        }
        else{
          return 'white'
        }
      },

      // Customize font color for column table for result WQI status
      getFontColor(wqi){
        if (wqi > 92.7){
          return 'white--text'
        }
        else if (wqi > 76.5 && wqi <= 92.7) {
          return 'black--text'
        }
        else if (wqi >= 50 && wqi <= 76.5) {
          return 'black--text'
        }
        else if (wqi >= 31.0 && wqi <= 51.9) {
          return 'white--text'
        }
        else if(wqi >= 1 && wqi <= 30.9){
          return 'white--text'
        }
        else{
          return 'black--text'
        }
      },

      exportReport(){

          let valueHeader = [];
          for (let i = 0; i < this.thead_rawData.length; i++) {
              valueHeader.push(this.thead_rawData[i].value);
          }

          // console.log(valueHeader);

          let keys = valueHeader,
          result = this.tbody_rawData.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

          // console.log(result);
          // result.forEach(function(res){
          //   res["DATETIME"] = res["DATETIME"].replaceAll('<br>','T');
          // });

          var blob = new Blob([Papa.unparse(this.specialExcel(this.thead_rawData,result))], { type: 'text/csv;charset=utf-8;' });

          var link = document.createElement("a");

          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", 'Raw Data For ('+ this.valStation +') From '+ this.convertDateFormat(this.dateFrom) +' To '+ this.convertDateFormat(this.dateTo) +' .csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        
      },
      
    },
    mounted() {
      
      // load anything first
      this.load();
      // this.getParamStation();
    }

}
</script>



<style lang="scss">

    @import '~scss/main';

     /* Section Path File/Modul */
    .pathModul{
      padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a{
      color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
      padding: 0px;
    }

    .col-input-filter{
      padding: 0px 10px;
    }

    .col-btn-generateReport{
        margin-bottom: 18px;
    }

    .btn-generate{
        margin: 0px !important;
        // background: #4495D1 !important;
    }


    .v-text-field.v-text-field--solo .v-input__control,
    .btn-generate .v-btn .v-btn--contained{
        min-height: 10px;
        margin-top: 10px;
    }

    /* Section Result Generated Report */
    .container-result-generated{
      background: white;
      padding: 5px 0px;
    }

    .subTitle{
      text-align: center !important;
      background: white;
      // color: #1976D2 !important;
      display: none;
    }

    // .v-data-table-header > tr > th{
    //   color: white !important;
    //   background: #4495D1 !important;
    //   padding: 5px 15px !important;
    // }


    .header-colspan > tr > th{
      text-align: center !important;
      /* border-left:  0.1px solid #e0e0e0; */
      border-right:  0.1px solid #e0e0e0;
      background: #607d8b;
      color: white !important;
    }

    .tableRawData{
      margin: 10px 0px;
      border: 0.1px solid #e0e0e0;
    }

    .tableRawData > div:nth-child(1) > table > tbody > tr > td:nth-child(1){
      white-space: nowrap !important;
    }

    .container-result-generated{
      text-align: center;
    }

    .vChip-text{
      color:black !important;
    }

    .tableRawData > .v-data-table__wrapper{
      max-height: 600px;
    }

    .v-data-table__empty-wrapper > td{
      text-align: left !important;
    }

    /* Footer Report */
    .one-text-footer{
      margin: 0px 10px;
    }

</style>